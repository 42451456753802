<template lang="">
    <section>
        <p class='title1'>Validation Cannot Proceed</p>
        <div class="container">
          <p class="text">?</p>
          <img src="@/assets/box_rejected.svg" alt="" >
        </div>
       
        <p class="title2">We’re unable to verify this product’s distribution without your permission to access your location</p>
    </section>
</template>
<script>
export default {

}
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css?family=Poppins');

.title1 {
  font-family: "Poppins", sans-serif;
  font-weight: bolder;
  margin-bottom: 40px;
}

.title2 {
  font-family: "Poppins", sans-serif;
  margin-top: 30px;
  font-size: 14px;
  color: #818181;


}

.container {
  position: relative;
}

.text {
  font-family: "Poppins", sans-serif;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  font-weight: bolder;
  color: white;
  font-size: 8rem;
  opacity: 0.7;
}

img {
  max-width: 100%;
  height: auto;
}
</style>