<template lang="">
    <a-row type="flex" justify="center">
      <a-col :span="24" :lg='5'>
        
    <div class="container">
        <img src="@/assets/box.svg" style="width:30%;"/>
        <hr class="spacer"/>
        <a-row class="section">
            <a-col :span="24" >
                <a-row v-if="!scanResult.cross_border">
                    <a-col :span="20" class="title_success">
                        Valid Distribution
                    </a-col>
                    <a-col :span="4" style="text-align:right;">
                        <img src="@/assets/check.png" />
                    </a-col>
                </a-row>
                <a-row v-else>
                    <a-col :span="20" class="title_failed">
                        Invalid Distribution
                    </a-col>
                    <a-col :span="4" style="text-align:right;">
                        <img src="@/assets/cross.png" />
                    </a-col>
                </a-row>
                <a-row class="row_detail">
                    <a-col :span="12" class="title2">
                        QR Code
                    </a-col>
                    <a-col :span="10" class="value2">
                        {{qrcode}}
                    </a-col>
                    <a-col :span="2">
                        <EyeOutlined v-if="!isOpen" @click="toggleViewQrcode" />
                        <EyeInvisibleOutlined v-else @click="toggleViewQrcode" />
                    </a-col>
                </a-row>
                <a-row class="row_detail">
                    <a-col :span="12" class="title2">
                        Scan Date
                    </a-col>
                    <a-col :span="12" class="value2">
                    
                       {{tanggal}}
                    </a-col>
                </a-row>
                <a-row class="row_detail">
                    <a-col :span="12" class="title2">
                        Scan Location
                    </a-col>
                    <a-col :span="12" class="value2">
                      {{scanResult.scan_location.city_name}}, {{scanResult.scan_location.province_name}}
                    </a-col>
                </a-row>
            </a-col>
        </a-row>
        <hr class="spacer"/>
        <a-row class="section">
            <a-col :span="24" >
                <a-row>
                    <a-col :span="20" class="title3" @click="alterSpesifikasi">
                        Spesification
                    </a-col>
                    <a-col :span="4" style="text-align:right;">
                        <img src="@/assets/chevron.png" :class="open?'open':'close'" @click="alterSpesifikasi" />
                    </a-col>
                </a-row>
               
                <a-row class="row_detail" v-if="open">
                        <a-col :span="12" class="title2">
                            Distributor
                        </a-col>
                        <a-col :span="12" class="value2">
                            {{scanResult.mapping_distribution.distributor}}
                        </a-col>
                </a-row>
                <a-row class="row_detail" v-if="open">
                        <a-col :span="12" class="title2">
                            Region
                        </a-col>
                        <a-col :span="12" class="value2">
                            {{scanResult.mapping_distribution.region}}

                        </a-col>
                </a-row>
                <a-row class="row_detail" v-if="open">
                        <a-col :span="12" class="title2">
                            Province
                        </a-col>
                        <a-col :span="12" class="value2">
                            {{scanResult.mapping_distribution.province}}
                            
                        </a-col>
                </a-row>
                <a-row class="row_detail" v-if="open">
                        <a-col :span="12" class="title2">
                            City
                        </a-col>
                        <a-col :span="12" class="value2">
                            {{scanResult.mapping_distribution.city}}
                            
                        </a-col>
                </a-row>
           
            </a-col>

            <a-col :span="24">
                <a-button block class="button1" @click="resScan" >Rescan</a-button>
            </a-col>
            
        </a-row>
    </div>
</a-col>
    </a-row>
</template>
<script>
import moment from "moment"
import { mapState, mapActions } from 'vuex';
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons-vue"
import { toHandlers } from "vue";
export default {
    components:{
        EyeOutlined,
        EyeInvisibleOutlined
    },
    data() {
        return {
            open: false,
            tanggal: "",
            qrcode: "",
            isOpen : false
        }
    },
    methods:{
        alterSpesifikasi(){
            this.open =  !this.open
        },
        toggleViewQrcode(){
            this.isOpen = !this.isOpen
            if(this.isOpen == true){
                this.qrcode = this.scanResult.qrcode
            }else{
                if(this.scanResult.qrcode != ""){
                    this.qrcode = this.scanResult.qrcode.slice(0, -4) + "****";
                }
            }
        },
        resScan(){
            let data = {
                cross_border: false,
                scan_location: {
                    region: "",
                    region_name: "",
                    province: "",
                    province_name: "",
                    city: "",
                    city_name: ""
                },
                mapping_distribution: {
                    region: "",
                    province: "",
                    city: "",
                    distributor: ""
                },
                qrcode: "",
                scan_date: ""
            }
            this.updateScanResult(data);
            this.$router.push({path:'/scan', replace: true})
        },
        ...mapActions(['updateScanResult'])
    },
    created(){
        //this.scanResult.qrcode = "https://google.com"
        this.tanggal = moment(this.scanResult.scan_date, 'YYYY-MM-DD HH:mm').format('DD MMM | HH:mm')
        //this.qrcode = this.scanResult.qrcode
        
        if(this.scanResult.qrcode != undefined && this.scanResult.qrcode != ""){
            this.qrcode = this.scanResult.qrcode.slice(0, -4) + "****";
        }
    },
    computed:{
        ...mapState(['scanResult'])
    }
    
}
</script>
<style scoped>
.container {
    margin-top:10vh;
}
.title_success{
    text-align:left;
    color:green;
    font-weight:bolder;
}
.title_failed{
    text-align:left;
    color:red;
    font-weight:bolder;
}
.title2{
    text-align:left;
    font-size:12px;

}
.title3{
    text-align:left;
    font-weight:bolder;
}
.value2{
    text-align:right;
    font-size:12px;
    font-weight:bolder;
}
.row_detail{
    margin-top:20px;
}
.section{
    margin-top:30px;
    margin-left:30px;
    margin-right:30px;
    margin-bottom:30px;
}
.spacer{
    border:0.2px solid #E2E2E2;
}
.open{
    transform: rotate(0deg);

}
.close{
    transform: rotate(270deg);

}
.button1 {
  font-family: "Poppins", sans-serif;
  margin-top: 60px;
  background-color: green;
  color: white;
  font-weight: bolder;
  height: 45px;
  font-size: 12px;
}
</style>