<template lang="">
    <section>
        <p class='title1'>Validate Distribution</p>
        <img src="@/assets/box.svg" alt="" >
        <p class="title2">We need to access your location to validate this product</p>
    </section>
</template>
<script>
export default {
    
}
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css?family=Poppins');

.title1{
  font-family: "Poppins", sans-serif;
  font-weight: bolder;
  margin-bottom: 50px;
  font-size: 16px;
}
.title2{
  margin-top: 40px;
  font-size: 14px;
  color: #818181;
  font-family: "Poppins", sans-serif;
  }
</style>