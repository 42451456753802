import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate';

export default createStore({
  state: {
    accessLocation: 0,
    location: {
      latitude: "",
      longitude: ""
    },
    isSuccess: false,
    scanResult: {
      cross_border: false,
      scan_location: {
        region: "",
        region_name: "",
        province: "",
        province_name: "",
        city: "",
        city_name: ""
      },
      mapping_distribution: {
        region: "",
        province: "",
        city: "",
        distributor: ""
      },
      qrcode: "",
      scan_date: ""
    }
  },
  getters: {
  },
  mutations: {
    updateAccessLocation(state, payload) {
      state.accessLocation = payload
    },
    updateLocation(state, payload) {
      state.location.lattitude = payload.lattitude
      state.location.longitude = payload.longitude
    },
    updateScanResult(state, payload){
      state.scanResult = payload
    }

  },
  actions: {
    updateAccessLocation({ commit }, payload) {
      commit("updateAccessLocation", payload)
    },
    updateLocation({ commit }, payload) {
      commit("updateLocation", payload)
    },
    updateScanResult({commit}, payload){
      commit('updateScanResult', payload)
    }
  },
  modules: {
  },
  plugins: [
    createPersistedState({
      storage: window.localStorage, // or window.sessionStorage
    }),
  ],
})
