<template lang="">
    <a-row type="flex" justify="center">
      <a-col :span="24" :lg='5'>

    <div class="container">
        <img src="@/assets/evalube.png" width="200" v-if="domain == 'sevalube-agent.sequre.id'"/>
        <img src="@/assets/sucofindo.png" width="350" v-else/>
        <br />
        <p>{{deviceInfo.make}} - {{agent}}</p>
        <a-alert :message=errorMessage type="error" v-if="errorMessage" style="width:100%;margin-bottom:10px"></a-alert>
        <!-- <a-alert :message=qrcode type="success" v-if="qrcode" style="width:100%;margin-bottom:10px"></a-alert> -->
        
        <p class="title1">To continue, please scan QR by pressing button bellow</p>
        <!-- <input id="upload" ref="fileInput" type="file" class="form-control border-0" accept="image/*" capture="environment" @change="handleFileSelect"   > -->
        <div class="label">
            <div class="seq-scan-effect-5">
                <div class="seq-scan-effect-10">
                    <!-- <img src="@/assets/icon-scan.svg" class="seq-icon-scan"> -->
                    <label for="fileInput" class="custom-file-input">
                        <img src="@/assets/icon-scan.svg" class="seq-icon-scan"> 
                    </label>
                    <input id="fileInput" ref="fileInput" type="file" class="form-control border-0" accept="image/*" capture="environment" @change="handleFileSelect" style="display: none"
                    
                    />
                </div>
            </div>
        </div>
       <!--  </button> -->
        <h2>Tips to ease the process</h2>
        <ul class="seq-tips-list">

            <li>Make sure image is clear and not blurry</li>
            <li>Adjust QR code position in the area</li>
            <li>Adjust QR code size below 70% of the frame</li>
            <li>Pinch to Zoom from your smartphone camera to get 70% of QR code from the frame</li>
        </ul>
       <!--  <img :src="resizedImage" alt="Resized Image" /> -->
        <div>

        </div>
        <SpinnerComponent v-if="isLoading"/>
    </div>
    </a-col>
</a-row>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import SpinnerComponent from "./../../components/others/spinnerComponent.vue"
import axios from 'axios';
import moment from 'moment';
export default {
    components: {
        SpinnerComponent
    },
    data() {
        return {
            agent:"",
            domain: "",
            isImage: false,
            base64Image: null,
            latitude: null,
            longitude: null,
            isLoading: false,
            errorMessage: '',
            qrcode: "",
            score: 0,
            status: "",
            resizedImage: "",
            deviceInfo: {
                browserName: "",
                make: "",
                model: ""
            },
        };
    },
    created() {
        console.log(window.location.host);
        this.getBrowserName()
        this.getMobileDeviceInfo()
        this.domain = window.location.host;
    },
    methods: {
        getMobileDeviceInfo() {
            const userAgent = navigator.userAgent;
            //console.log(navigator.userAgentData);

            // Regular expressions to match common mobile device patterns
            const mobileDeviceRegex = /(Android|iPhone|iPod|iPad|Windows Phone)/;
            const mobileMakeRegex = /(Samsung|Apple|Google|LG|HTC|Motorola)/;
            const mobileModelRegex = /\b(?:SM-\w+|iPhone\s\w+|iPad\s\w+|\w+\s+Build)/;
            
            /* const test = new UserAgent();
            console.log(test.toString());
            console.log(JSON.stringify(test.data, null, 2)); */

            if (mobileDeviceRegex.test(userAgent)) {
                // Extract the device make and model
                const makeMatch = userAgent.match(mobileMakeRegex);
                const modelMatch = userAgent.match(mobileModelRegex);

                if (makeMatch) {
                    //this.deviceInfo.make = makeMatch[0];
                    this.deviceInfo.make = navigator.userAgentData.platform;
                }
                if (modelMatch) {
                    this.deviceInfo.model = modelMatch[0];
                }
            }
            console.log(`make ${this.deviceInfo.make}`);
            console.log(`model ${this.deviceInfo.model}`);

        },
        getBrowserName() {
            const userAgent = navigator.userAgent;
            this.agent = userAgent

            if (/MSIE|Trident/.test(userAgent)) {
                this.deviceInfo.browserName = 'Internet Explorer';
            } else if (/Edge/.test(userAgent)) {
                this.deviceInfo.browserName = 'Microsoft Edge';
            } else if (/Firefox/.test(userAgent)) {
                this.deviceInfo.browserName = 'Mozilla Firefox';
            } else if (/OPR|Opera/.test(userAgent)) {
                this.deviceInfo.browserName = 'Opera';
            } else if (/Chrome/.test(userAgent)) {
                this.deviceInfo.browserName = 'Google Chrome';
            } else if (/Safari/.test(userAgent) && /Apple/.test(userAgent)) {
                this.browserName = 'Apple Safari';
            } else {
                this.deviceInfo.browserName = 'Unknown Browser';
            }
            //console.log(this.deviceInfo.browserName);
        },
        async openFileBrowser() {
            if ("geolocation" in navigator) {
                try {
                    await navigator.geolocation.getCurrentPosition(this.handleLocationSuccess, this.handleLocationError);
                } catch (error) {
                    console.error('Error requesting location permission:', error);
                }

            } else {
                alert("Geolocation is not supported by your browser.");

            }
        },
        handleLocationSuccess(position) {
            this.latitude = position.coords.latitude;
            this.longitude = position.coords.longitude;
            this.$refs.fileInput.click();
        },
        handleLocationError(error) {
            if (error.code === error.PERMISSION_DENIED) {
                this.updateAccessLocation(2)
                this.$router.push({ path: "/" })
            }
            console.error('Location request error:', error);
        },


        async actionScanDistribution() {
            let postData = {
                "qrcode": this.qrcode,
                "lat": this.latitude,
                "lng": this.longitude,
                "score": this.score,
                "status": this.status,
                "model": this.deviceInfo.browserName,
                "device": this.deviceInfo.make,
                "brand": this.deviceInfo.model
            }
            axios.post(`${process.env.VUE_APP_ENDPOINT}/api/scan-distribution`, postData)
                .then((response) => {
                    let data = response.data.data;
                    this.updateScanResult(data)
                    this.$router.push({ path: '/result', replace: true })
                })
                .catch((error) => {
                    this.errorMessage = error.response.data.message
                })
        },
        async handleFileSelect(event) {
            if ("geolocation" in navigator) {
                try {
                    let self = this
                    await navigator.geolocation.getCurrentPosition(function (position) {
                        self.latitude = position.coords.latitude;
                        self.longitude = position.coords.longitude;
                        const selectedFile = event.target.files[0];
                        if (selectedFile && self.isImageFile(selectedFile)) {
                            self.isImage = true;
                            //await this.convertToBase64(selectedFile);
                            const reader = new FileReader();
                            reader.onload = (event) => {
                                const img = new Image();
                                img.src = event.target.result;

                                img.onload = () => {
                                    const canvas = document.createElement('canvas');
                                    const ctx = canvas.getContext('2d');

                                    const aspectRatio = img.width / img.height;
                                    const newWidth = 1024;
                                    const newHeight = newWidth / aspectRatio;

                                    canvas.width = newWidth;
                                    canvas.height = newHeight;

                                    // Draw the image on the canvas with the new size
                                    ctx.drawImage(img, 0, 0, newWidth, newHeight);

                                    // Convert the canvas content to a base64-encoded URL
                                    const resizedImageUrl = canvas.toDataURL('image/jpeg');

                                    // Set the resized image URL to the data property
                                    self.resizedImage = resizedImageUrl;

                                    const base64String = resizedImageUrl.split(',')[1];
                                    //console.log('Resized Image Base64:', base64String);

                                    let url = `${process.env.VUE_APP_ENDPOINT_CLASSIFCATION}/upload`
                                    let pid = moment().toDate()
                                    let dataPost = {
                                        //imageb64str: event.target.result
                                        imageb64str: base64String,
                                        pid: pid
                                    }
                                    axios.post(url, dataPost)
                                        .then((response) => {
                                            self.isLoading = false;
                                            console.log(response);
                                            if(response.data.status == "OK"){
                                                if(response.data.label=="genuine"){
                                                    self.score = response.data.score_classification;
                                                    self.qrcode = response.data.qrcode
                                                    self.status = response.data.label
                                                    self.errorMessage = ""
                                                    self.actionScanDistribution();
                                                }else{
                                                    console.log('counterfet');
                                                    self.$router.push({path:'/counterfeit', replace:true})
                                                }
                                                
                                            }else{

                                                if(response.data.label == "Can't Detect QR Code"){
                                                    self.$router.push({path:'/qr-not-detected', replace:true})

                                                }else{
                                                    self.$router.push({path:'/canvas-not-detected', replace:true})

                                                }

                                               // self.errorMessage = response.data.label
                                            }
                                           /*  if (response.data.status != "OK") {
                                                self.errorMessage = response.data.label
                                                
                                            } else {
                                                
                                            } */
                                        })
                                        .catch((error) => {
                                            self.isLoading = false;
                                            console.log(error);
                                        })

                                };


                            };
                            reader.readAsDataURL(selectedFile);
                            self.isLoading = true;
                            /* setTimeout(function(){
                                self.isLoading = false;
                                self.$router.push({path:'/success'});
                            }, 2000); */

                        } else {
                            alert('File isnot valid format (use only .jpg file)')
                            self.isImage = false;
                            self.base64Image = '';
                        }
                    }, self.handleLocationError);
                } catch (error) {
                    console.error('Error requesting location permission:', error);
                }

            } else {
                alert("Geolocation is not supported by your browser.");

            }

        },
        isImageFile(file) {
            return file.type.startsWith("image/");
        },
        isJpgFile(file) {
            return file.name.toLowerCase().endsWith('.jpg') || file.type === 'image/jpeg';
        },
        async convertToBase64(file) {
            const reader = new FileReader();
            reader.onload = (event) => {
            };
            reader.readAsDataURL(file);
        },
        ...mapActions(['updateLocation', 'updateAccessLocation', 'updateScanResult'])

    }
}
</script>
<style scoped>
.container {
    height: 90vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-left: 2rem;
    margin-right: 2rem;

}

.label {
    display: inline-block;
    text-align: center;
    background: none;
    border: none;
    color: inherit;
    /* Use the text color of the parent element */
    text-decoration: underline;
    /* Optionally add an underline to mimic a link appearance */
    cursor: pointer;
}

.seq-scan-effect-5 {
    background: rgb(38, 124, 229, 0.05);
    border-radius: 50%;
    width: 188px;
    height: 188px;
    position: relative;
}

.seq-scan-effect-10 {
    background: rgb(38, 124, 229, 0.1);
    border-radius: 50%;
    width: 152px;
    height: 152px;
    position: absolute;
    margin: 18px;
}

.seq-icon-scan {
    border-radius: 50%;
    width: 116px;
    height: 116px;
    margin: 18px;
}

.title1 {
    font-size: 0.8rem;
    margin-bottom: 20px;
}

ul.seq-tips-list {
    list-style-type: none;
    padding: 0;

}

h2 {
    font-size: 1.2rem;
    font-weight: bolder;
    margin-top: 20px;
    margin-bottom: 20px;
}

.seq-tips-list li {
    font-size: 0.7rem;
    color: #989898;
    list-style-type: none;
    padding: 3px 0;
    text-align: left;
    font-size: 12px;
}

.custom-file-input {
    background-repeat: no-repeat;
    background-position: right;
    color: white;
    cursor: pointer;
    border-radius: 70px;
    display: inline-block;
}

.custom-file-input:hover {
    background-color: none;
}
</style>