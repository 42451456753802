<template lang="">
  <a-row type="flex" justify="center" class="container">
  <a-col  :span="24" :lg="5" >
    <RejectPermissionComponent v-if="accessLocation == 2" />
    <EmptyPermissionComponent v-else />
    <a-button block class="button1" @click="resetLocationAccess" v-if="accessLocation == 2">Grant Location Access</a-button>
    <a-button block class="button1" @click="getLocation" v-else>Grant Location Access</a-button>
  </a-col>
</a-row>

</template>
<script>
import { mapActions, mapState } from 'vuex';
import EmptyPermissionComponent from '@/components/access_location/EmptyPermissionComponent.vue';
import RejectPermissionComponent from '@/components/access_location/RejectPermissionComponent.vue';
import { toHandlers } from 'vue';
export default {
  components: {
    EmptyPermissionComponent,
    RejectPermissionComponent
  },
  data() {
    return {
      latitude: null,
      longitude: null,
    };
  },
  methods: {
    async resetLocationAccess() {
      console.log('reset');
      this.updateAccessLocation(0)
      alert('Please reset your browser permission, then click "Grant Location Access"');
      /* const permissionToRemove = {
        permissions: ["geolocation"],
      };
      const removed = await browser.permissions.remove(permissionToRemove);
      console.log(removed); */
    },
    getLocation() {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
          let data = {
            longitude: this.longitude,
            latitude: this.latitude
          }
          this.updateLocation(data)
          this.updateAccessLocation(1)
          this.$router.push({ path: "/scan" })

        }, (error) => {
          console.log(error);
          this.updateAccessLocation(2)

        });
      } else {
        alert("Geolocation is not supported by your browser.");
        this.updateAccessLocation(2)

      }
    },

    ...mapActions(['updateLocation', 'updateAccessLocation'])
  },
  computed: {
    ...mapState(["accessLocation"])
  },
  created(){
    console.log('v.1.0.1');
  }

}
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css?family=Poppins');

.container {
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 20vh;

}

.button1 {
  font-family: "Poppins", sans-serif;
  margin-top: 60px;
  background-color: green;
  color: white;
  font-weight: bolder;
  height: 45px;
  font-size: 12px;
}
</style>