import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
//import ScanProcessView from "../views/scan/ScanProcessView.vue"
import ScanFailedView from "../views/scan/ScanFailedView.vue"
import ScanSuccessView from "../views/scan/ScanSuccessView.vue"
import ResetView from "../views/reset/ResetView.vue"
import NotFoundView from "../views/scan/NotFoundView.vue"
import ScanProcessView from "../views/scan/ScanProcessView.vue"
import ScanResultView from "../views/scan/ScanResultView"
import ScanCanvasNotDetectedView from "../views/scan/ScanCanvasNotDetectedView.vue"
import ScanQrNotDetectedView from "../views/scan/ScanQrNotDetectedView.vue"
import ScanCounterfeitView from "../views/scan/ScanCounterfeitView.vue"

import store from "./../store"

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/scan',
    name: 'Scan',
    component: ScanProcessView,
    meta: { requestLocation: true }
  },
  {
    path: '/failed',
    name: 'Failed',
    component: ScanFailedView
  },
 /*  {
    path: '/dev',
    name: 'Dev',
    component: DevScanView
  }, */
  {
    path: '/result',
    name: 'Result',
    component: ScanResultView,
    //meta: { requestSucess: false }
  },
  {
    path: '/success',
    name: 'Success',
    component: ScanSuccessView,
    meta: { requestSucess: false }
  },
  {
    path: '/notfound',
    name: 'Not Found',
    component: NotFoundView,
  },
  {
    path: '/reset',
    name: 'Reset',
    component: ResetView,
  },
  {
    path: '/counterfeit',
    name: 'Counterfeit',
    component: ScanCounterfeitView,
  },
  {
    path: '/qr-not-detected',
    name: 'QR Not Detected',
    component: ScanQrNotDetectedView,
  },
  {
    path: '/canvas-not-detected',
    name: 'Canvas Not Detected',
    component: ScanCanvasNotDetectedView,
  }


]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.name;
  /* if(to.matched.some(record => record.name == "Home" && store.state.accessLocation == 1)){
    next("/scan");
  }else{
    if ((to.matched.some(record => record.meta.requestLocation)) && store.state.accessLocation != 1) {
      next("/")
    } else {
      if ((to.matched.some(record => record.meta.requestSucess)) && store.state.isSuccess == false) {
      next("/scan")
      }else{
        next();
      }
    }
  } */
  next();
  
});



export default router
