<template lang="">
    <a-row type="flex" justify="center" class="container">
    <a-col :span="24" :lg="5">
        <img src="@/assets/box.svg" style="width:30%;"/>
        <hr class="spacer"/>
        <a-row class="section">
            <a-col :span="24" >
                <a-row>
                    <a-col :span="20" class="title1">
                        Distribusi Tidak Sesuai
                    </a-col>
                    <a-col :span="4" style="text-align:right;">
                        <img src="@/assets/cross.png" />
                    </a-col>
                </a-row>
                <a-row class="row_detail">
                    <a-col :span="12" class="title2">
                        QR Code
                    </a-col>
                    <a-col :span="12" class="value2">
                        32324354******
                    </a-col>
                </a-row>
                <a-row class="row_detail">
                    <a-col :span="12" class="title2">
                        Tanggal Scan
                    </a-col>
                    <a-col :span="12" class="value2">
                        7 Okt | 13:21
                    </a-col>
                </a-row>
                <a-row class="row_detail">
                    <a-col :span="12" class="title2">
                        Lokasi Scan
                    </a-col>
                    <a-col :span="12" class="value2">
                        DKI Jakarta, Jakarta Timur
                    </a-col>
                </a-row>
            </a-col>
        </a-row>
        <hr class="spacer"/>
        <a-row class="section">
            <a-col :span="24" >
                <a-row>
                    <a-col :span="20" class="title3" @click="alterSpesifikasi">
                        Spesifikasi
                    </a-col>
                    <a-col :span="4" style="text-align:right;">
                        <img src="@/assets/chevron.png" :class="open?'open':'close'" @click="alterSpesifikasi" />
                    </a-col>
                </a-row>
               
                <a-row class="row_detail" v-if="open">
                        <a-col :span="12" class="title2">
                            Distributor
                        </a-col>
                        <a-col :span="12" class="value2">
                            Panca Jaya
                        </a-col>
                </a-row>
                <a-row class="row_detail" v-if="open">
                        <a-col :span="12" class="title2">
                            Region
                        </a-col>
                        <a-col :span="12" class="value2">
                            Indonesia
                        </a-col>
                </a-row>
                <a-row class="row_detail" v-if="open">
                        <a-col :span="12" class="title2">
                            Provinsi
                        </a-col>
                        <a-col :span="12" class="value2">
                            DKI Jakarta
                        </a-col>
                </a-row>
                <a-row class="row_detail" v-if="open">
                        <a-col :span="12" class="title2">
                            Kota
                        </a-col>
                        <a-col :span="12" class="value2">
                            Jakarta Timur
                        </a-col>
                </a-row>
           
            </a-col>
            
        </a-row>
    </a-col>
</a-row>

</template>
<script>
export default {
    data() {
        return {
            open: false
        }
    },
    methods:{
        alterSpesifikasi(){
            this.open =  !this.open
        }
    }
    
}
</script>
<style scoped>
.container {
    margin-top:10vh;
}
.title1{
    text-align:left;
    color:red;
    font-weight:bolder;
}
.title2{
    text-align:left;
    font-size:12px;

}
.title3{
    text-align:left;
    font-weight:bolder;
}
.value2{
    text-align:right;
    font-size:12px;
    font-weight:bolder;
}
.row_detail{
    margin-top:20px;
}
.section{
    margin-top:30px;
    margin-left:30px;
    margin-right:30px;
    margin-bottom:30px;
}
.spacer{
    border:0.2px solid #ccc;
}
.open{
    transform: rotate(0deg);

}
.close{
    transform: rotate(270deg);

}
</style>