<template lang="">
    <div>
        <p>Processing...</p>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
    methods:{
        resetData(){
            let location = {
                latitude:"",
                longitude: ""
            }
            this.updateAccessLocation(0)
            this.updateLocation(location)
            this.$router.push({path: "/", replace:"true"})
        },  
        ...mapActions(["updateAccessLocation", "updateLocation"])
    },
    mounted:function(){
        this.resetData()
    }
}
</script>
<style lang="">
    
</style>